import * as zod from 'zod';

import { InputPortID } from './InputPort';
import { OutputPortID } from './OutputPort';

export const ExternalControlSettings = zod.object({
  isEnabled: zod.boolean().default(false),
  isRobotIOEnabled: zod.boolean().default(false),
  ioPorts: zod
    .object({
      // inputs
      play: InputPortID.optional(),
      pause: InputPortID.optional(),
      loadDefaultRoutine: InputPortID.optional(),
      // outputs
      ready: OutputPortID.optional(),
      running: OutputPortID.optional(),
      fault: OutputPortID.optional(),
      defaultRoutineLoaded: OutputPortID.optional(),
    })
    .default({}),
  defaultRoutineID: zod.string().nullable().default(null),
});

export type ExternalControlSettings = zod.infer<typeof ExternalControlSettings>;

import * as zod from 'zod';

import { OutputPortID } from '@sb/routine-runner/types/OutputPort';

export const IntegrationInterfaceKind = zod.enum(['controlBoxIO', 'haasNGC']);

export type IntegrationInterfaceKind = zod.infer<
  typeof IntegrationInterfaceKind
>;

export const IntegrationActionControlBoxIOInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.controlBoxIO),
  outputs: zod
    .object({
      ioPort: OutputPortID.nullable().default(null),
      isHigh: zod.boolean().default(true),
      isPulse: zod.boolean().default(true),
    })
    .array()
    .default([{}]),
  pulseSeconds: zod.number().default(0.5),
});

export type IntegrationActionControlBoxIOInterface = zod.infer<
  typeof IntegrationActionControlBoxIOInterface
>;

const IntegrationActionHaasNGCInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.haasNGC),
  program: zod.string().default(''),
});

export type IntegrationActionHaasNGCInterface = zod.infer<
  typeof IntegrationActionHaasNGCInterface
>;

const IntegrationActionInterface = zod.discriminatedUnion('kind', [
  IntegrationActionControlBoxIOInterface,
  IntegrationActionHaasNGCInterface,
]);

export type IntegrationActionInterface = zod.infer<
  typeof IntegrationActionInterface
>;

export const IntegrationActionKind = zod.enum([
  'clamp',
  'unclamp',
  'open',
  'close',
  'on',
  'off',
  'start',
  'stop',
  'custom',
]);

export type IntegrationActionKind = zod.infer<typeof IntegrationActionKind>;

export const IntegrationAction = zod.object({
  kind: IntegrationActionKind,
  name: zod.string(),
  interface: IntegrationActionInterface,
});

export type IntegrationAction = zod.infer<typeof IntegrationAction>;

export const IntegrationActionGroup = zod.object({
  name: zod.string(),
  actions: IntegrationAction.array().default([]),
});

export type IntegrationActionGroup = zod.infer<typeof IntegrationActionGroup>;

export function isIntegrationActionMatch(
  action: IntegrationAction,
  matchKind: IntegrationActionKind,
  matchName?: string,
) {
  return (
    action.kind === matchKind &&
    (matchKind !== 'custom' || action.name === matchName)
  );
}

export function isIntegrationActionConfigured(action: IntegrationAction) {
  switch (action.interface.kind) {
    case 'controlBoxIO': {
      return (
        action.interface.outputs.length > 0 &&
        action.interface.outputs.every((o) => o.ioPort !== null)
      );
    }
    case 'haasNGC': {
      return action.interface.program !== '';
    }
    default:
      return false;
  }
}

export function isIntegrationActionGroupConfigured(
  value: IntegrationActionGroup,
) {
  return (
    value.actions.length > 0 &&
    value.actions.every(isIntegrationActionConfigured)
  );
}

import * as zod from 'zod';

import { OutputPortID, IOLevel } from '../../types';

export default zod.object({
  changes: zod.array(
    zod.object({
      label: OutputPortID,
      level: IOLevel,
    }),
  ),
});
